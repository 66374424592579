<div class="container-fluid">
	<div class="row">
		<div class="col">
			<!--
			<a class="btn btn-primary" href="https://hangouts.google.com/call/XIXYzcr5BzTPQMeGfMXGACEI"
				target="_blank">🤠 Join Hangout </a> 
			-->
		</div>
		<div class="col-10">
			<div class="maxContainer">


				<app-hero></app-hero>

				<!--
				<div class="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
					<app-maptracking></app-maptracking>
					

				</div>
				-->

			</div>
		</div>
		<div class="col">

		</div>
	</div>
</div>