<nav class="navbar navbar-expand-lg navbar-dark  bg-dark rounded">
  <a class="navbar-brand" href="#">❤️ OscarTheBus</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09"
    aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse ml-auto" id="navbarsExample09">
    <ul class="navbar-nav ml-auto">
      <li class="navbar-nav ml-auto">
      </li>
      <li class="nav-item active">

        <!--
        <a class="bmc-button" 
          target="_blank" href="https://www.buymeacoffee.com/oscarthebus">
          <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy Us A Coffee">
          <span style="margin-left:auto;font-size:auto !important;">Buy Us A Coffee</span>
        </a>
        -->
      </li>

      <li class="nav-item active">
        <a class="nav-link" href="//instagram.com/loveyourcamper" target="_blank">🤠 Instagram </a>
      </li>
    </ul>

  </div>
</nav>