<div class=" hero-image rounded text-center m-md-3 position-relative overflow-hidden">
  <img src="../../assets/hero_image.jpg" class="img-fluid " alt="">
  <div class="col-md-5 p-lg-5 mx-auto my-5 centered bg-transparent">
    <div class="mediaQuery">


      <h1 class="font-weight-normal text-white">🌍 Oscar’s Restauration</h1>
      <p class="lead font-weight-normal text-white">Follow our Restauration Journey.</p>
      <style>.bmc-button img{height: 34px !important;width: 35px !important;margin-bottom: 1px !important;box-shadow: none !important;border: none !important;vertical-align: middle !important;}.bmc-button{padding: 7px 15px 7px 10px !important;line-height: 35px !important;height:51px !important;text-decoration: none !important;display:inline-flex !important;color:#ffffff !important;background-color:#FF813F !important;border-radius: 5px !important;border: 1px solid transparent !important;padding: 7px 15px 7px 10px !important;font-size: 20px !important;letter-spacing:-0.08px !important;box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;-webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;margin: 0 auto !important;font-family:'Lato', sans-serif !important;-webkit-box-sizing: border-box !important;box-sizing: border-box !important;}.bmc-button:hover, .bmc-button:active, .bmc-button:focus {-webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;text-decoration: none !important;box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;opacity: 0.85 !important;color:#ffffff !important;}</style><link href="https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext" rel="stylesheet"><a class="bmc-button" target="_blank" href="https://www.buymeacoffee.com/oscarthebus"><img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy Us A Coffee"><span style="margin-left:5px;font-size:19px !important;">Buy Us A Coffee</span></a>
      <br>
    <a class="btn btn-primary" href="https://hangouts.google.com/call/XIXYzcr5BzTPQMeGfMXGACEI"
    target="_blank">Get Your Postcard </a>
    </div>

  </div>
</div>



<div
  class="mediaQuery2 bg-secondary m-b-5 mb-2 mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden bottom-padding rounded">

  <div class="my-3 p-3">
    <h2 class="display-5">🌍 Oscar’s Restauration </h2>

    <p class="lead">Follow our Restauration Journey.</p>
  </div>
  <div class="container">
    <style>.bmc-button img{height: 34px !important;width: 35px !important;margin-bottom: 1px !important;box-shadow: none !important;border: none !important;vertical-align: middle !important;}.bmc-button{padding: 7px 15px 7px 10px !important;line-height: 35px !important;height:51px !important;text-decoration: none !important;display:inline-flex !important;color:#ffffff !important;background-color:#FF813F !important;border-radius: 5px !important;border: 1px solid transparent !important;padding: 7px 15px 7px 10px !important;font-size: 20px !important;letter-spacing:-0.08px !important;box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;-webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;margin: 0 auto !important;font-family:'Lato', sans-serif !important;-webkit-box-sizing: border-box !important;box-sizing: border-box !important;}.bmc-button:hover, .bmc-button:active, .bmc-button:focus {-webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;text-decoration: none !important;box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;opacity: 0.85 !important;color:#ffffff !important;}</style><link href="https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext" rel="stylesheet"><a class="bmc-button" target="_blank" href="https://www.buymeacoffee.com/oscarthebus"><img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy Us A Coffee"><span style="margin-left:5px;font-size:19px !important;">Buy Us A Coffee</span></a>
    <br>
    <a class="btn btn-primary" href="https://hangouts.google.com/call/XIXYzcr5BzTPQMeGfMXGACEI"
    target="_blank">Get Your Postcard </a>
  </div>

  <!--<div class="bg-light shadow-sm mx-auto" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;"></div>-->
</div>