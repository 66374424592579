import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oscar',
  templateUrl: './oscar.component.html',
  styleUrls: ['./oscar.component.css']
})
export class OscarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
