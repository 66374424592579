<footer class="footer mt-auto rounded py-3 text-center">
  <div class="container">
    <span class="text-muted">This will be an amazing footer</span>
    <div class="bmc-button-container">
      <link href="https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext" rel="stylesheet"><a class="bmc-button" 
      target="_blank" href="https://www.buymeacoffee.com/oscarthebus">
      <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy Us A Coffee">
      <span style="margin-left:auto;font-size:auto !important;">Buy Us A Coffee</span></a>
    </div>
  </div>
</footer>