<div class="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden bottom-padding rounded">

  <div class="my-3 p-3">
    <h2 class="display-5">📹 Live Stream</h2>
    <p class="lead">What are we up to?</p>
    
    
  </div>
  <div class="container">


      <div class="facebook-responsive">
   
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fkirchhoffandre%2Fvideos%2F10224321150353479%2F&width=640" width="640" height="480" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
      </div>
      
  <!--<div class="bg-light shadow-sm mx-auto" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;"></div>-->
</div>