<app-navigation></app-navigation>

<main>
  <router-outlet></router-outlet>

  <div class="container text-center my-6 p-6">

    <h2 class="display-5">Most Recent YouTube Video </h2>
    
    <iframe style="max-width:700px; width: 100%; height: 400px" src="https://www.youtube.com/embed/k8xY07UJA5k"
    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>

    <br><br>

    <iframe style="max-width:700px; width: 100%; height: 400px" src="https://www.youtube.com/embed/Rb6LBRwzJVw"
    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>

    <br><br>

    <iframe style="max-width:700px; width: 100%; height: 400px" src="https://www.youtube.com/embed/xAC9eOyNxKo"
    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>

    <br><br>

    <iframe style="max-width:700px; width: 100%; height: 400px" src="https://www.youtube.com/embed/9MOOw5xy0R4"
    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>

    <br><br>

    <iframe style="max-width:700px; width: 100%; height: 400px" src="https://www.youtube.com/embed/lX4HUFcgWmc"
      frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>

      <br><br>

      <iframe style="max-width:700px; width: 100%; height: 400px" src="https://www.youtube.com/embed/c0ZjwWJ45Bs"
      frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>

    <p class="lead">
      <a class="btn btn-danger" href="https://www.youtube.com/channel/UCxNHANKVvee819CSxb6UEtA/videos" target="_blank">...
        More YouTube Videos </a> </p>

  </div>
</main>





<app-footer></app-footer>